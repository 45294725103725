<template>
  <div>
    <b-card-group deck v-if="false">
      <b-card
        header="Avg. Work Hrs "
        header-tag="header"
        title="08:11"
        style="max-width: 20rem"
      >
        <b-card-text>-18% From April</b-card-text>
      </b-card>

      <b-card
        header="Avg. Work Hrs "
        header-tag="header"
        title="08:11"
        style="max-width: 20rem"
      >
        <b-card-text>-18% From April</b-card-text>
      </b-card>

      <b-card
        header="Avg. Work Hrs "
        header-tag="header"
        title="08:11"
        style="max-width: 20rem"
      >
        <b-card-text>-18% From April</b-card-text>
      </b-card>

      <div
        class="my-auto text-info"
        id="show-btn"
        @click="$bvModal.show('bv-modal-example')"
      >
        +3 INSIGHTS
      </div>

      <b-modal id="bv-modal-example" hide-footer size="lg">
        <template #modal-title>
          Insights for attendance period May 01 - May 08
        </template>
        <div class="d-block text-center">
          <b-list-group horizontal="md">
            <b-list-group-item
              v-for="(item, index) in insights.listItem"
              v-bind:key="index"
            >
              <p class="text-uppercase font-weight-bold">{{ item.title }}</p>
              <h2>{{ item.value }}</h2>
              <p>{{ item.desc }}</p>
            </b-list-group-item>
          </b-list-group>

          <AvgTimeChart :user-data="userAttendanceData" />
        </div>
        <b-button class="mt-3" block @click="$bvModal.hide('bv-modal-example')"
          >Close Me</b-button
        >
      </b-modal>
    </b-card-group>
    <b-row>
      <b-col cols="12">
        <Calendar :user-hashid.sync="userHashid" v-if="renderComponent" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Calendar from "./Calendar.vue";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";

import { BRow, BCol } from "bootstrap-vue";
import AvgTimeChart from "./AvgTimeChart.vue";

import store from "@/store";
import { onUnmounted, watch, ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import attendanceStoreModule from "../attendanceStoreModule";

export default {
  components: {
    BRow,
    BCol,
    AvgTimeChart,
    Calendar,
  },
  props: {
    userHashid: {
      type: String,
      required: true,
    },
  },
  setup() {
    const REFERRAL_APP_STORE_MODULE_NAME = "app-attendance";

    // Register module
    if (!store.hasModule(REFERRAL_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        REFERRAL_APP_STORE_MODULE_NAME,
        attendanceStoreModule
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REFERRAL_APP_STORE_MODULE_NAME))
        store.unregisterModule(REFERRAL_APP_STORE_MODULE_NAME);
    });

    return {};
  },
  data() {
    return {
      renderComponent: true,
      calendarEvents: [],
      userData: this.$cookies.get("userData"),
      calendarPlugins: [
        dayGridPlugin,
        interactionPlugin,
        timeGridPlugin,
        listPlugin,
      ],
      month: "05",
      year: new Date().getFullYear(),
      insights: {
        listItem: [
          {
            title: "Avg. Work Hrs ",
            value: "08:11",
            desc: "-18% From April",
          },
          {
            title: "Avg. Work Hrs ",
            value: "08:11",
            desc: "-18% From April",
          },
          {
            title: "Avg. Work Hrs ",
            value: "08:11",
            desc: "-18% From April",
          },
          {
            title: "Avg. Work Hrs ",
            value: "08:11",
            desc: "-18% From April",
          },
          {
            title: "Avg. Work Hrs ",
            value: "08:11",
            desc: "-18% From April",
          },
          {
            title: "Avg. Work Hrs ",
            value: "08:11",
            desc: "-18% From April",
          },
        ],
      },
      userAttendanceData: {
        labels: ["VueJs", "EmberJs", "ReactJs", "AngularJs"],
        datasets: [
          {
            backgroundColor: ["#41B883", "#E46651", "#00D8FF", "#DD1B16"],
            data: [40, 20, 80, 10],
          },
        ],
      },
      series: [
        {
          name: "Total Employees",
          data: [
            {
              backgroundColor: ["#41B883", "#E46651", "#00D8FF", "#DD1B16"],
              data: [40, 20, 80, 10],
            },
          ],
        },
      ],
    };
  },
  watch: {
    userHashid: function (newVal, oldVal) {
      // watch it
      this.forceRerender();
      // console.log(this.refetchEvents());
      // console.log("Prop changed: ", newVal, " | was: ", oldVal);
    },
  },

  methods: {
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    fetchAttendanceCalendarData() {
      const self = this;

      self.formData.url = "attendance-calendar-data/" + this.userData.hashid;
      self.formData.params = { month: this.month, year: this.year };
      this.$store
        .dispatch("app/get", self.formData)
        // this.$store
        //   .dispatch("app-attendance/fetchAttendanceCalendarData", {
        //     hashid: this.userData.hashid,
        //     queryParams: { month: this.month, year: this.year },
        //   })
        .then((res) => {
          this.calendarEvents = res.data.data;
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "fetch Employee List failed.",
              icon: "BellIcon",
              variant: "danger",
              text: err.toString(),
            },
          });
        });
    },
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>

